import React from 'react';

const HeroSec = () => {
    return (
        <div>
            <div className='hero-bg'>
                {/* <h4 className='text-center text-white'>Hero Section Here</h4> */}
            </div>
        </div>
    );
}

export default HeroSec;
